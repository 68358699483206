<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDATeams',
  metaInfo: { title: 'Teams' },
  extends: View,

  mixins: [
    LoadSections([
      // NOTE : current update not use teamsHeader and teamMember so comment for now, mybe will use in future so not to delete
      // 'KDATeamsHeader',
      // 'KDATeamsMember',
      'KDATeamsStructure',
      'KDAAboutAskInterest',
      'KDABookBanner'
    ])
  ],

  props: {
    id: {
      type: String,
      default: 'teams'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      page_title: 'Teams'
    });
  }
};
</script>
